<template>
  <v-container
    id="foods"
    fluid
    tag="section"
    class="container-full-size"
  >
    <v-data-table
      :headers="headers"
      :items="foodsFiltered"
      :search="search"
      :no-data-text="str['foods_empty']"
      :no-results-text="str['foods_empty']"
      class="elevation-1"
      @click:row="editFood"
    >
      <template v-slot:top>
        <v-toolbar
          v-if="hasParentCategory"
          flat
          class="toolbar-adjust table-toolbar-no-padding-sides pb-0"
        >
          <v-row>
            <v-col
              cols="12"
              class="pb-1"
            >
              <v-tabs
                v-model="filters.parentCategory"
                background-color="transparent"
                color="secondary"
                style="margin-top: 0;"
                grow
                show-arrows
              >
                <v-tab
                  v-for="tab in parentCategories"
                  :key="tab.value"
                >
                  {{ tab.label }}
                </v-tab>
              </v-tabs>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-toolbar
          flat
        >
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="str['search']"
            single-line
            hide-details
          />
          <v-spacer />
          <v-btn
            v-if="showExportCsv && editPermission"
            color="secondary"
            dark
            class="mb-2"
            @click="exportCsv()"
            style="margin-right: 10px;"
          >
            {{ str['export'] }}
          </v-btn>
          <v-dialog
            v-model="dialogFood"
            persistent
            max-width="750px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="editPermission"
                color="success"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
                @click="openNewFood()"
              >
                {{ str['add'] }}
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">
                  {{ formTitle }}
                </span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      v-for="item in foodData"
                      :key="item.id"
                      :cols="item.cols ? item.cols : 12"
                      v-bind:style="{'pointer-events': editPermission ? '' : 'none'}"
                      :class="[item.parent ? 'pt-0 pb-0' : '']"
                    >
                      <div
                        v-if="item.parent"
                        class="label-parent pt-0 pb-0"
                      >
                        {{ item.parent }}
                      </div>
                      <v-text-field
                        v-if="item.type === 'input' && !item.callback"
                        v-model="item.value"
                        @blur="item.value = item.value ? item.value.trim() : item.value"
                        :label="item.title"
                        :disabled="item.disabled ? true : false"
                        hide-details
                      />
                      <v-text-field
                        v-if="item.type === 'input' && item.callback"
                        v-model="item.value"
                        :label="item.title"
                        :disabled="item.disabled ? true : false"
                        @input="item.callback"
                        @blur="item.value = item.value ? item.value.trim() : item.value"
                        hide-details
                      />
                      <v-textarea
                        v-if="item.type === 'textarea'"
                        v-model="item.value"
                        :label="item.title"
                        :disabled="item.disabled ? true : false"
                        rows="3"
                        hide-details
                      />
                      <v-checkbox
                        v-if="item.type === 'checkbox'"
                        v-model="item.value"
                        :label="item.title"
                        class="form-field-checkbox"
                        hide-details
                      />
                      <v-autocomplete
                        v-if="item.type === 'select'"
                        v-model="item.value"
                        :label="item.title"
                        item-text="label"
                        item-value="value"
                        :items="item.items"
                        :multiple="item.multiple"
                        :no-data-text="str['no_data']"
                        :search-input.sync="item.searchInput"
                        @change="item.callback(item)"
                        hide-details
                      >
                        <div
                          v-if="item.selectAll"
                          slot="prepend-item"
                          ripple
                          @click.stop="item.value.length !== item.items.length ? item.value = item.items.map(it => it.value) : item.value = []"
                          class="checkbox-element cursor-hover"
                        >
                          <v-icon v-if="item.value.length !== item.items.length">
                            mdi-checkbox-blank-outline
                          </v-icon>
                          <v-icon v-if="item.value.length === item.items.length">
                            mdi-checkbox-marked
                          </v-icon>
                          <div>
                            {{ str['select_all'] }}
                          </div>
                        </div>
                      </v-autocomplete>
                      <div v-if="item.type === 'image'">
                        <div>
                          {{ str['image'] }}
                        </div>
                        <div
                          v-if="item.value"
                          class="form-field-image"
                        >
                          <input
                            id="food-input-image"
                            type="file"
                            @change="addNewImage"
                            accept=".png,.jpg,.jpeg"
                          >
                          <v-img
                            contain
                            :src="item.value"
                            alt="Conteúdo"
                            width="300px"
                            height="300px"
                          />
                        </div>
                        <div
                          v-if="!item.value && editPermission"
                          class="form-field-image"
                        >
                          <input
                            id="food-input-image"
                            type="file"
                            @change="addNewImage"
                            accept=".png,.jpg,.jpeg"
                          >
                          <v-btn
                            class="mx-2"
                            fab
                            dark
                            small
                            color="success"
                          >
                            <v-icon dark>
                              mdi-plus
                            </v-icon>
                          </v-btn>
                        </div>
                      </div>
                      <div
                        v-if="item.type === 'gallery'"
                        style="position: relative;"
                      >
                        <v-carousel v-model="currentImage">
                          <v-carousel-item
                            v-for="(img,i) in item.value"
                            :key="i"
                            :src="img"
                            contain
                            reverse-transition="fade-transition"
                            transition="fade-transition"
                          />
                        </v-carousel>
                        <div class="title button-actions">
                          <v-btn
                            class="mx-2"
                            fab
                            dark
                            small
                            color="red"
                            @click="deleteGalleryImage"
                          >
                            <v-icon dark>
                              mdi-delete
                            </v-icon>
                          </v-btn>
                          <v-btn
                            v-if="currentImage > 0"
                            class="mx-2"
                            fab
                            dark
                            small
                            color="default"
                            @click="pinGalleryImage"
                          >
                            <v-icon dark>
                              mdi-pin
                            </v-icon>
                          </v-btn>
                          <v-btn
                            v-if="currentImage === 0"
                            class="mx-2"
                            fab
                            dark
                            small
                            color="primary"
                            style="pointer-events: none;"
                          >
                            <v-icon dark>
                              mdi-pin
                            </v-icon>
                          </v-btn>
                          <input
                            id="input-file-image"
                            type="file"
                            @change="addNewGalleryImage"
                            accept=".png,.jpg,.jpeg"
                          >
                          <v-btn
                            class="mx-2"
                            fab
                            dark
                            small
                            color="success"
                          >
                            <v-icon dark>
                              mdi-plus
                            </v-icon>
                          </v-btn>
                        </div>
                      </div>
                      <div
                        v-if="item.type === 'alternatives'"
                      >
                        <div class="pb-3">
                          <h4>
                            {{ str['attention'] }}
                          </h4>
                          <h5 class="label-text-break">
                            {{ str['food_alternatives_attention'] }}
                          </h5>
                        </div>
                        <table class="simple-table theme">
                          <thead>
                            <tr>
                              <th
                                v-for="altHeaderItem in item.headers"
                                :key="altHeaderItem.id"
                              >
                                {{ str[altHeaderItem.name] ? str[altHeaderItem.name] : altHeaderItem.name }}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(altItem, altItemIndex) in item.value"
                              :key="altItemIndex"
                            >
                              <td
                                v-for="(altHeader, altHeaderIndex) in item.headers"
                                :key="altHeaderIndex"
                              >
                                <div class="row-flex-align-center">
                                  <input
                                    v-if="altHeader.type === 'input' && altHeader.inputType !== 'numeric'"
                                    v-model="altItem[altHeader.value]"
                                  />
                                  <input
                                    v-if="altHeader.type === 'input' && altHeader.inputType === 'numeric'"
                                    v-model="altItem[altHeader.value]"
                                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                  />
                                  <v-autocomplete
                                    v-if="altHeader.type === 'select'"
                                    v-model="altItem[altHeader.value]"
                                    item-text="label"
                                    item-value="value"
                                    :items="altHeader.items"
                                    :no-data-text="str['no_data']"
                                    style="width: 140px;"
                                    hide-details
                                    outlined
                                  />
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="default"
                  @click="closeFood"
                >
                  {{ str['cancel'] }}
                </v-btn>
                <v-btn
                  v-if="editPermission"
                  color="success"
                  @click="saveFood"
                >
                  {{ str['save'] }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
        <v-toolbar
          v-if="hasCategory"
          flat
          class="toolbar-adjust"
        >
          <v-row>
            <v-col
              :cols="12"
              :lg="4"
              :md="4"
              :sm="12"
            >
              <v-autocomplete
                v-model="filters.category"
                :label="str['category']"
                item-text="label"
                item-value="value"
                :items="foodCategoriesList"
                :no-data-text="str['no_data']"
                hide-details
                multiple
                clearable
                style="max-width: 300px"
              />
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:item.type="{ item }">
        {{ foodTypesDict[item.type] ? foodTypesDict[item.type] : '-' }}
      </template>
      <template v-slot:item.parent_category="{ item }">
        {{ foodParentCategoriesDict[item.parent_category] ? foodParentCategoriesDict[item.parent_category] : '-' }}
      </template>
      <template v-slot:item.category="{ item }">
        {{ foodCategoriesDict[item.category] ? foodCategoriesDict[item.category] : '-' }}
        {{ hasCategory2 ? (' | ' + (foodCategoriesDict[item.category2] ? foodCategoriesDict[item.category2] : '-')) : '' }}
      </template>
      <template v-slot:item.food_group="{ item }">
        <div v-if="foodGroupsDict[item.food_group]">
          {{ foodGroupsDict[item.food_group] }}
        </div>
        <div v-if="foodGroupsDict[item.food_group2]">
          {{ foodGroupsDict[item.food_group2] }}
        </div>
        <div v-if="!foodGroupsDict[item.food_group] && !foodGroupsDict[item.food_group2]">
          -
        </div>
      </template>
      <template v-slot:item.app_show="{ item }">
        {{ item.app_show ? str['yes'] : str['no'] }}
      </template>
      <template v-slot:item.excluded_countries="{ item }">
        <span class="ellipsis-rows-2">
          {{ item.excluded_countries ? item.excluded_countries : '-' }}
        </span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          class="mr-0"
          color="error"
          @click.stop="deleteFood(item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
        {{ pageStart }}-{{ pageStop }} {{ str['of'] }} {{ itemsLength }}
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
  import Api from '@/services/Api'
  import Utils from '@/services/Utils'

  export default {
    data () {
      const user = Utils.getUser()
      const editPermission = Utils.hasPermission('food_list_edit')
      const hasParentCategory = user && user.configurations && user.configurations.food && user.configurations.food.has_parent_category
      const hasCategory = user && user.configurations && user.configurations.food && user.configurations.food.has_category
      const hasCategory2 = user && user.configurations && user.configurations.food && user.configurations.food.has_category2
      const hasGroup = user && user.configurations && user.configurations.food && user.configurations.food.has_group
      const hasAppAvailabilityControl = user && user.configurations && user.configurations.food && user.configurations.food.has_app_availability_control
      const hasAppShow = user && user.configurations && user.configurations.food && user.configurations.food.has_app_show
      const hasExcludedCountries = user && user.configurations && user.configurations.food && user.configurations.food.has_excluded_countries
      const hasIntolerances = user && user.configurations && user.configurations.food && user.configurations.food.has_intolerances
      const hasAutoValues = user && user.configurations && user.configurations.food && user.configurations.food.has_auto_values
      const notesData = user && user.configurations && user.configurations.food && user.configurations.food.notes
      const hasAlternatives = user && user.configurations && user.configurations.food && user.configurations.food.has_alternatives
      const hasTranslations = user && user.configurations && user.configurations.food && user.configurations.food.has_translations
      const disableAutoCalories = user && user.configurations && user.configurations.food && user.configurations.food.disable_auto_calories
      const showExportCsv = user && user.configurations && user.configurations.food && user.configurations.food.show_export_csv
      const headers = [
        { text: window.strings['id'], value: 'id', width: 80 },
        { text: window.strings['name'], value: 'name', align: 'left', width: 200 },
      ]
      if (hasAppAvailabilityControl) {
        headers.push({ text: window.strings['app_name'], value: 'app_name', align: 'center', width: 200 })
      }
      if (hasAppAvailabilityControl || hasAppShow) {
        headers.push({ text: window.strings['app_show'], value: 'app_show', align: 'center', width: 120 })
      }
      if (hasParentCategory) {
        headers.push({ text: window.strings['filter_name'], value: 'parent_category', align: 'center', width: 200 })
      }
      if (hasCategory) {
        headers.push({ text: window.strings['category'], value: 'category', align: 'center', width: 300 })
      }
      if (hasGroup) {
        headers.push({ text: window.strings['group'], value: 'food_group', align: 'center', width: 200 })
      }
      if (hasExcludedCountries) {
        headers.push({ text: window.strings['excluded_countries'], value: 'excluded_countries', align: 'center', width: 250 })
      }
      headers.push({ text: window.strings['type'], value: 'type', align: 'center', width: 100 })
      headers.push({ text: window.strings['value'], value: 'value', align: 'center', width: 100 })
      headers.push({ text: window.strings['calories_kcal'], value: 'calories', align: 'center', width: 120 })
      headers.push({ text: window.strings['protein_g'], value: 'protein', align: 'center', width: 120 })
      headers.push({ text: window.strings['carbohydrates_g'], value: 'carbs', align: 'center', width: 115 })
      headers.push({ text: window.strings['fat_g'], value: 'fat', align: 'center', width: 120 })
      if (editPermission) {
        headers.push({ text: window.strings['actions'], value: 'actions', sortable: false, align: 'center', width: 80 })
      }

      return {
        str: window.strings,
        user: user,
        editPermission: editPermission,
        hasParentCategory: hasParentCategory,
        hasCategory: hasCategory,
        hasCategory2: hasCategory2,
        hasGroup: hasGroup,
        hasAppAvailabilityControl: hasAppAvailabilityControl,
        hasAppShow: hasAppShow,
        hasExcludedCountries: hasExcludedCountries,
        hasIntolerances: hasIntolerances,
        hasAutoValues: hasAutoValues,
        notesData: notesData,
        hasAlternatives: hasAlternatives,
        hasTranslations: hasTranslations,
        disableAutoCalories: disableAutoCalories,
        showExportCsv: showExportCsv,
        foods: [],
        editedIndex: -1,
        dialogFood: false,
        foodData: [],
        foodTypes: Utils.getFoodTypes(),
        foodTypesDict: this.getFoodTypes(),
        parentCategories: Utils.getFoodParentCategories(),
        foodParentCategoriesDict: this.getFoodParentCategoriesDict(),
        foodCategoriesDict: this.getFoodCategoriesDict(),
        foodCategoriesList: Utils.getFoodCategories(),
        foodGroupsDict: this.getFoodGroupsDict(),
        imageMaxSize: 800,
        search: '',
        headers: headers,
        filters: {
          parentCategory: 0,
          category: null,
        },
        currentImage: 0,
        imagesKeys: ['image', 'image2', 'image3', 'image4'],
        updateRecipes: false,
      }
    },
    computed: {
      foodsFiltered() {
        const self = this;
        let foods = this.foods
        if (this.hasParentCategory) {
          const parentCategory = this.parentCategories[this.filters.parentCategory]
          foods = foods.filter(function (item) {
            return parentCategory.value === item.parent_category
          })
        }
        if (this.filters.category && this.filters.category.length) {
          foods = foods.filter(function (item) {
            return self.filters.category.indexOf(item.category) > -1 || (self.hasCategory2 && self.filters.category.indexOf(item.category2) > -1 )
          })
        }
        return foods
      },
      formTitle () {
        return this.editedIndex === -1 ? window.strings['new_food'] : window.strings['edit_food']
      },
    },
    watch: {
      dialogFood (val) {
        const dialog = document.getElementsByClassName('v-dialog')
        if (dialog && dialog[0]) {
          dialog[0].scrollTo(0, 0)
        }

        val || this.closeFood()
      },
    },
    beforeMount: function () {
      this.main()
    },
    methods: {
      main: function () {
        if (!this.user) {
          return false
        }
        this.getFoodFields()
        this.getFoods()
        this.getCountries()
        if (!window.useResetCacheSockets) {
          Utils.removeRequestCache('foods')
        }
      },
      getFoodFields: function () {
        const self = this
        const fields = []
        fields.push({
          type: 'input',
          id: 'name',
          title: window.strings['name'],
          value: '',
          visible: true,
        })
        if (this.hasTranslations) {
          this.hasTranslations.forEach(function (tr) {
            fields.push({
              type: 'input',
              id: 'name_' + tr,
              title: window.strings['name'] + ' (' + tr.toUpperCase() + ')',
              value: '',
              visible: true,
            })
          })
        }
        if (this.user && this.user.configurations && this.user.configurations.food && this.user.configurations.food.has_app_availability_control) {
          fields.push({
            type: 'input',
            id: 'app_name',
            title: window.strings['app_name'],
            value: '',
            visible: true,
          })
          if (this.hasTranslations) {
            this.hasTranslations.forEach(function (tr) {
              fields.push({
                type: 'input',
                id: 'app_name_' + tr,
                title: window.strings['app_name'] + ' (' + tr.toUpperCase() + ')',
                value: '',
                visible: true,
              })
            })
          }
        }
        if (this.user && this.user.configurations && this.user.configurations.food && (this.user.configurations.food.has_app_availability_control || this.user.configurations.food.has_app_show)) {
          fields.push({
            type: 'checkbox',
            id: 'app_show',
            title: window.strings['app_show'],
            value: '',
            visible: true,
          })
        }
        if (this.user && this.user.configurations && this.user.configurations.food && this.user.configurations.food.has_app_value_hide) {
          fields.push({
            type: 'checkbox',
            id: 'app_value_hide',
            title: window.strings['app_value_hide'],
            value: '',
            visible: true,
          })
        }
        fields.push({
          type: 'select',
          id: 'type',
          title: window.strings['type'],
          items: Utils.getFoodTypes(),
          value: '',
          searchInput: '',
          visible: true,
          callback: function (item) {
            item.searchInput = ''
            const itemValue = item.items.find(function (f) {
              return f.value === item.value
            })
            for (let d = 0; d < self.foodData.length; d++) {
              if (self.foodData[d].number) {
                if (itemValue && itemValue.disable_macros) {
                  self.foodData[d].value = 0
                  self.foodData[d].disabled = true
                } else {
                  self.foodData[d].disabled = false
                }
              }
              if (self.foodData[d].id === 'value') {
                if (itemValue && itemValue.quantity_fixed) {
                  self.foodData[d].value = itemValue.quantity_fixed
                  self.foodData[d].disabled = true
                } else {
                  self.foodData[d].disabled = false
                }
              }
            }
          }
        })
        fields.push({
          type: 'input',
          id: 'value',
          title: window.strings['value'],
          value: '',
          visible: true,
          number: true,
          callback: function () {
            self.updateRecipes = true
          }
        })
        fields.push({
          type: 'input',
          id: 'calories',
          title: window.strings['calories_kcal'],
          value: '',
          visible: true,
          number: true,
          callback: function () {
            self.updateRecipes = true
          }
        })
        fields.push({
          type: 'input',
          id: 'protein',
          title: window.strings['protein_g'],
          value: '',
          visible: true,
          number: true,
          callback: function () {
            self.calculateFoodCalories()
            self.updateRecipes = true
          }
        })
        fields.push({
          type: 'input',
          id: 'carbs',
          title: window.strings['carbohydrates_g'],
          value: '',
          visible: true,
          number: true,
          callback: function () {
            self.calculateFoodCalories()
            self.updateRecipes = true
          }
        })
        fields.push({
          type: 'input',
          id: 'fat',
          title: window.strings['fat_g'],
          value: '',
          visible: true,
          number: true,
          callback: function () {
            self.calculateFoodCalories()
            self.updateRecipes = true
          }
        })

        if (this.user && this.user.configurations && this.user.configurations.food_plans && this.user.configurations.food_plans.show_sub_macros) {
          fields.push({
            type: 'input',
            id: 'fiber',
            title: window.strings['fiber_g'],
            value: '',
            visible: true,
            number: true,
            callback: function () {
              self.updateRecipes = true
            }
          })
          fields.push({
            type: 'input',
            id: 'sodium',
            title: window.strings['sodium_g'],
            value: '',
            visible: true,
            number: true,
            callback: function () {
              self.updateRecipes = true
            }
          })
          fields.push({
            type: 'input',
            id: 'sugar',
            title: window.strings['sugar_g'],
            value: '',
            visible: true,
            number: true,
            callback: function () {
              self.updateRecipes = true
            }
          })
        }

        if (this.user && this.user.configurations && this.user.configurations.food_plans && this.user.configurations.food_plans.has_url) {
          fields.push({
            type: 'input',
            id: 'url',
            title: window.strings['url'],
            value: '',
            visible: true,
          })
        }

        if (this.hasParentCategory) {
          fields.push({
            type: 'select',
            id: 'parent_category',
            title: window.strings['filter_name'],
            items: Utils.getFoodParentCategories(),
            value: '',
            searchInput: '',
            visible: true,
            callback: function (item) {
              item.searchInput = ''
            }
          })
        }

        if (this.hasCategory) {
          fields.push({
            type: 'select',
            id: 'category',
            title: window.strings['category'],
            items: Utils.getFoodCategories(),
            value: '',
            searchInput: '',
            visible: true,
            callback: function (item) {
              item.searchInput = ''
            }
          })
        }

        if (this.hasCategory2) {
          fields.push({
            type: 'select',
            id: 'category2',
            title: window.strings['category'],
            items: Utils.getFoodCategories(),
            value: '',
            searchInput: '',
            visible: true,
            callback: function (item) {
              item.searchInput = ''
            }
          })
        }

        if (this.hasGroup) {
          fields.push({
            type: 'select',
            id: 'food_group',
            title: window.strings['group'],
            items: Utils.getFoodGroups(),
            value: '',
            searchInput: '',
            visible: true,
            callback: function (item) {
              item.searchInput = ''
            }
          })
          fields.push({
            type: 'select',
            id: 'food_group2',
            title: window.strings['group'],
            items: Utils.getFoodGroups(),
            value: '',
            searchInput: '',
            visible: true,
            callback: function (item) {
              item.searchInput = ''
            }
          })
        }

        if (this.hasExcludedCountries) {
          fields.push({
            type: 'select',
            id: 'excluded_countries',
            title: window.strings['excluded_countries'],
            items: [],
            value: '',
            searchInput: '',
            multiple: true,
            selectAll: true,
            visible: true,
            callback: function (item) {
              item.searchInput = ''
            }
          })
        }

        if (this.hasIntolerances) {
          fields.push({
            type: 'select',
            id: 'intolerances',
            title: window.strings['intolerances'],
            items: Utils.getFoodIntolerance().filter(function (i) {
              return i.nutrition_category
            }),
            value: [],
            searchInput: '',
            multiple: true,
            selectAll: true,
            visible: true,
            callback: function (item) {
              item.searchInput = ''
            }
          })
        }

        if (this.notesData) {
          fields.push({
            type: 'textarea',
            id: 'notes',
            title: this.notesData.title,
            value: '',
            visible: true,
          })
        }

        if (this.hasAutoValues) {
          fields.push({
            parent: window.strings['automatic_adjustments'],
          })
          fields.push({
            type: 'input',
            cols: 4,
            id: 'auto_min',
            title: window.strings['min'],
            value: '',
            visible: true,
            number: true
          })
          fields.push({
            type: 'input',
            cols: 4,
            id: 'auto_inc',
            title: window.strings['inc_dec'],
            value: '',
            visible: true,
            number: true
          })
          fields.push({
            type: 'input',
            cols: 4,
            id: 'auto_max',
            title: window.strings['max'],
            value: '',
            visible: true,
            number: true
          })
        }

        if (this.hasAlternatives) {
          const alternativesHeaders = []
          alternativesHeaders.push({
            name: window.strings['alternative'],
            value: 'alternative',
            type: 'input',
          })
          if (this.hasTranslations) {
            this.hasTranslations.forEach(function (tr) {
              alternativesHeaders.push({
                name: window.strings['alternative'] + ' (' + tr.toUpperCase() + ')',
                value: 'alternative_' + tr,
                type: 'input',
              })
            })
          }
          alternativesHeaders.push({
            name: window.strings['type'],
            value: 'type',
            type: 'select',
            items: Utils.getFoodTypes(),
          }, {
            name: window.strings['ratio_equivalence'],
            value: 'ratio',
            type: 'input',
            inputType: 'numeric',
          })
          fields.push({
            type: 'alternatives',
            headers: alternativesHeaders,
            id: 'alternatives',
            title: window.strings['alternatives'],
            value: [],
            visible: true,
          })
        }

        if (this.user && this.user.configurations && this.user.configurations.food_plans && this.user.configurations.food_plans.has_multiple_image) {
          fields.push({
            type: 'gallery',
            id: 'images',
            title: window.strings['images'],
            value: [],
            visible: true,
          })
        } else if (this.user && this.user.configurations && this.user.configurations.food_plans && this.user.configurations.food_plans.has_image) {
          fields.push({
            type: 'image',
            id: 'image',
            title: window.strings['image'],
            value: '',
            visible: true,
          })
        }

        this.foodData = fields
      },
      calculateFoodCalories: function () {
        if (this.foodData) {
          let protein = 0
          let carbs = 0
          let fat = 0

          for (let i = 0; i < this.foodData.length; i++) {
            if (!this.foodData[i].id) {
              continue
            }
            if (typeof this.foodData[i].value === 'string') {
              this.foodData[i].value = this.foodData[i].value.replace(',', '.')
            }
            if (this.foodData[i].id === 'protein') {
              protein = parseFloat(this.foodData[i].value)
            }
            if (this.foodData[i].id === 'carbs') {
              carbs = parseFloat(this.foodData[i].value)
            }
            if (this.foodData[i].id === 'fat') {
              fat = parseFloat(this.foodData[i].value)
            }
          }

          if (!this.disableAutoCalories) {
            for (let i = 0; i < this.foodData.length; i++) {
              if (this.foodData[i].id === 'calories') {
                const val = protein * 4 + carbs * 4 + fat * 9
                this.foodData[i].value = Math.round(val * 10) / 10
                break
              }
            }
          }
        }
      },
      getFoodTypes: function () {
        const typesObj = {}
        const items = Utils.getFoodTypes()
        if (items && items.length) {
          for (let i = 0; i < items.length; i++) {
            typesObj[items[i].value] = items[i].label
          }
        }
        return typesObj
      },
      getFoodParentCategoriesDict: function () {
        const obj = {}
        const items = Utils.getFoodParentCategories()
        if (items) {
          for (let i = 0; i < items.length; i++) {
            obj[items[i].value] = items[i].label
          }
        }
        return obj
      },
      getFoodCategoriesDict: function () {
        const obj = {}
        const items = Utils.getFoodCategories()
        if (items) {
          for (let i = 0; i < items.length; i++) {
            obj[items[i].value] = items[i].label
          }
        }
        return obj
      },
      getFoodGroupsDict: function () {
        const obj = {}
        const items = Utils.getFoodGroups()
        if (items) {
          for (let i = 0; i < items.length; i++) {
            obj[items[i].value] = items[i].label
          }
        }
        return obj
      },
      getFoods: function () {
        const self = this
        this.$isLoading(true)
        Api.getFoods({
          photo: false,
        }, function (response) {
          self.$isLoading(false)
          if (response.success) {
            self.foods = response.data
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      getCountries: function () {
        const self = this
        if (this.hasExcludedCountries) {
          Api.getClientsCountries(function (response) {
            if (response.success) {
              for (let i = 0; i < self.foodData.length; i++) {
                if (self.foodData[i].id === 'excluded_countries') {
                  self.foodData[i].items = response.data.map(item => ({ label: item, value: item }))
                  break
                }
              }
            } else {
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        }
      },
      openNewFood () {
        this.setFoodData()
        this.editedIndex = -1
        this.dialogFood = true
        this.updateRecipes = false
      },
      getFoodData: function () {
        const self = this
        const data = {}

        for (let i = 0; i < this.foodData.length; i++) {
          if (!this.foodData[i].id) {
            continue
          }
          if (this.foodData[i].type === 'gallery') {
            this.imagesKeys.forEach(function (it) {
              data[it] = ''
            })
            if (this.foodData[i].value && this.foodData[i].value.length) {
              this.foodData[i].value.forEach(function (it, idx) {
                data[self.imagesKeys[idx]] = it
              })
            }
            continue
          }
          if (this.foodData[i].type === 'checkbox') {
            data[this.foodData[i].id] = this.foodData[i].value ? 1 : 0
            continue
          }
          if (this.foodData[i].id === 'excluded_countries') {
            data[this.foodData[i].id] = this.foodData[i].value ? this.foodData[i].value.join(', ') : ''
            continue
          }
          if (this.foodData[i].id === 'intolerances') {
            data[this.foodData[i].id] = this.foodData[i].value ? JSON.stringify(this.foodData[i].value) : ''
            continue
          }
          if (this.foodData[i].id === 'alternatives') {
            const alternativesValue = []
            if (this.foodData[i].value) {
              this.foodData[i].value.forEach(function (alt) {
                if (alt.alternative) {
                  alt.ratio = parseFloat(alt.ratio)
                  alternativesValue.push(alt)
                }
              })
            }
            data[this.foodData[i].id] = JSON.stringify(alternativesValue)
            continue
          }
          if (this.foodData[i].number) {
            data[this.foodData[i].id] = parseFloat(this.foodData[i].value)
            continue
          }
          data[this.foodData[i].id] = this.foodData[i].value
        }

        if (this.editedIndex > -1) {
          data.id = this.foods[this.editedIndex].id
        }

        return data
      },
      editFood: function (food) {
        const self = this
        this.$isLoading(true)
        Api.getFoods({
          photo: true,
          ids: [food.id]
        }, function (response) {
          self.$isLoading(false)
          if (response.success && response.data[0]) {
            self.editedIndex = self.foods.indexOf(food)
            self.setFoodData(response.data[0])
            self.updateRecipes = false
            self.dialogFood = true
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      setFoodData: function (data) {
        const self = this
        if (data) {
          for (let i = 0; i < this.foodData.length; i++) {
            if (!this.foodData[i].id) {
              continue
            }
            if (this.foodData[i].type === 'gallery') {
              this.foodData[i].value = []
              this.imagesKeys.forEach(function (it) {
                if (data[it]) {
                  self.foodData[i].value.push(data[it])
                }
              })
              continue
            }
            if (this.foodData[i].id === 'excluded_countries') {
              this.foodData[i].value = data[this.foodData[i].id] ? data[this.foodData[i].id].split(', ') : []
              continue
            }
            if (this.foodData[i].id === 'alternatives') {
              this.foodData[i].value = this.getAlternatives(data[this.foodData[i].id])
              continue
            }
            if (this.foodData[i].id === 'intolerances') {
              this.foodData[i].value = data[this.foodData[i].id] ? JSON.parse(data[this.foodData[i].id]) : []
              continue
            }
            if (data[this.foodData[i].id] !== undefined) {
              this.foodData[i].value = data[this.foodData[i].id]
            }
          }
        } else {
          for (let i = 0; i < this.foodData.length; i++) {
            if (!this.foodData[i].id) {
              continue
            }
            if (this.foodData[i].type === 'gallery') {
              this.foodData[i].value = []
              continue
            }
            if (this.foodData[i].id === 'parent_category') {
              this.foodData[i].value = this.parentCategories[this.filters.parentCategory].value
              continue
            }
            if (this.foodData[i].id === 'alternatives') {
              this.foodData[i].value = this.getAlternatives()
              continue
            }
            if (this.foodData[i].id === 'intolerances') {
              this.foodData[i].value = []
              continue
            }
            if (this.foodData[i].id === 'app_show') {
              this.foodData[i].value = 1
              continue
            }
            this.foodData[i].value = ''
          }
        }
      },
      getAlternatives (data) {
        const totalAlternatives = this.user && this.user.configurations && this.user.configurations.food && this.user.configurations.food.total_alternatives ? this.user.configurations.food.total_alternatives : 8
        const items = []
        if (data && typeof data === 'string') {
          data = JSON.parse(data)
        }
        for (let i = 0; i < totalAlternatives; i++) {
          if (data && data[i]) {
            items.push(data[i])
          } else {
            const obj = {
              alternative: '',
              type: 0,
              ratio: ''
            }
            if (this.hasTranslations) {
              this.hasTranslations.forEach(function (tr) {
                obj['alternative_' + tr] = ''
              })
            }
            items.push(obj)
          }
        }
        return items
      },
      closeFood () {
        this.dialogFood = false
        this.$nextTick(() => {
          this.editedIndex = -1
        })
      },
      saveFood () {
        const self = this
        const data = this.getFoodData()

        this.$isLoading(true)
        if (this.editedIndex < 0) {
          Api.addFood(data, function (response) {
            self.$isLoading(false)
            if (response.success) {
              self.foods.push(response.data)
              self.closeFood()
            } else {
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        } else {
          Api.updateFood(data, function (response) {
            self.$isLoading(false)
            if (response.success) {
              self.$set(self.foods, self.editedIndex, response.data)
              self.closeFood()
              self.refreshRecipes(response.data)
            } else {
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        }
      },
      refreshRecipes (food) {
        const self = this
        const config = this.user && this.user.configurations ? this.user.configurations : null
        if (config && config.food_plans && config.food_plans.show_recipes && this.updateRecipes) {
          this.$isLoading(true)
          Api.getRecipes({
            photo: false,
          }, function (response) {
            self.$isLoading(false)
            if (response.success) {
              response.data.forEach(function (item) {
                if (recipeHasFood(item)) {
                  updateRecipe(item)
                }
              })
            } else {
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        }

        function recipeHasFood (recipe) {
          if (recipe && recipe.foods) {
            const hasFood = recipe.foods.find(function (item) {
              return item.food_id === food.id
            })
            if (hasFood) {
              return true
            }
          }
          return false
        }

        function updateRecipe (recipe) {
          Api.updateRecipe(recipe, function (response) {
            if (!response.success) {
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        }
      },
      deleteFood (item) {
        const self = this
        const id = item.id

        this.$confirm(
          window.strings['want_delete_food'] + (item ? (' "' + item.name + '"') : '') + '?',
          '',
          'warning',
          Utils.getAlertOptions(true, true)
        ).then(() => {
          self.$isLoading(true)
          Api.deleteFood(id, function (response) {
            self.$isLoading(false)
            if (response.success) {
              for (let i = 0; i < self.foods.length; i++) {
                if (self.foods[i].id === id) {
                  self.foods.splice(i, 1)
                  break
                }
              }
            } else {
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        }).catch(() => { })
      },
      addNewImage (event, callback) {
        const self = this
        const file = event.srcElement.files[0]
        const reader = new FileReader()

        reader.readAsDataURL(file)
        reader.onload = function (e) {
          const image = new Image()
          image.src = reader.result

          image.onload = function () {
            let out = null
            if (image.width < self.imageMaxSize && image.height < self.imageMaxSize) {
              out = e.target.result
            } else {
              out = resizeImage(image)
            }
            if (callback) {
              callback(out)
            } else {
              self.saveNewImage(out)
            }
          }
        }
        reader.onerror = function () {}

        function resizeImage (image) {
          const canvas = document.createElement('canvas')
          let width = image.width
          let height = image.height

          if (width > height) {
            if (width > self.imageMaxSize) {
              height *= self.imageMaxSize / width
              width = self.imageMaxSize
            }
          } else {
            if (height > self.imageMaxSize) {
              width *= self.imageMaxSize / height
              height = self.imageMaxSize
            }
          }
          canvas.width = width
          canvas.height = height
          const ctx = canvas.getContext('2d')
          ctx.drawImage(image, 0, 0, width, height)
          return canvas.toDataURL()
        }
      },
      saveNewImage (image) {
        for (let i = 0; i < this.foodData.length; i++) {
          if (this.foodData[i].id === 'image') {
            this.foodData[i].value = image
            this.$set(this.foodData, i, this.foodData[i])
            break
          }
        }
        document.getElementById('food-input-image').value = ''
      },
      deleteGalleryImage () {
        for (let i = 0; i < this.foodData.length; i++) {
          if (this.foodData[i].type === 'gallery') {
            this.foodData[i].value.splice(this.currentImage, 1)
            this.$set(this.foodData, i, this.foodData[i])
            break
          }
        }
        this.currentImage = 0
      },
      pinGalleryImage () {
        for (let i = 0; i < this.foodData.length; i++) {
          if (this.foodData[i].type === 'gallery') {
            const newImages = []
            for (let v = 0; v < this.foodData[i].value.length; v++) {
              if (this.currentImage === v) {
                newImages.unshift(this.foodData[i].value[v])
              } else {
                newImages.push(this.foodData[i].value[v])
              }
            }
            this.foodData[i].value = newImages
            this.$set(this.foodData, i, this.foodData[i])
            break
          }
        }
        this.currentImage = 0
      },
      addNewGalleryImage (event) {
        const self = this
        this.addNewImage(event, function (img) {
          for (let i = 0; i < self.foodData.length; i++) {
            if (self.foodData[i].type === 'gallery') {
              self.foodData[i].value.push(img)
              self.$set(self.foodData, i, self.foodData[i])
              self.currentImage = self.foodData[i].value.length - 1
              break
            }
          }
        })
      },
      exportCsv () {
        const headers = [{
          value: 'id',
          text: window.strings['id']
        }, {
          value: 'name',
          text: window.strings['name']
        }]
        Utils.exportCsv({
          title: window.strings['foods'],
          headers: headers,
          data: this.foods
        })
      },
    },
  }
</script>
